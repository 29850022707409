import request from '@/utils/request';
export var saveGoods = function saveGoods(params) {
  return request({
    url: '/operation/goods',
    method: 'post',
    data: params
  });
};
export var updateGoods = function updateGoods(params) {
  return request({
    url: '/operation/goods',
    method: 'put',
    data: params
  });
};
export var queryGoods = function queryGoods(params) {
  return request({
    url: '/operation/goods',
    method: 'get',
    params: params
  });
};
export var deleteGoods = function deleteGoods(params) {
  return request({
    url: '/operation/goods',
    method: 'delete',
    data: params
  });
};
export var saveGiftActivity = function saveGiftActivity(params) {
  return request({
    url: '/operation/limitActivity',
    method: 'post',
    data: params
  });
};
export var updateGiftActivity = function updateGiftActivity(params) {
  return request({
    url: '/operation/limitActivity',
    method: 'put',
    data: params
  });
};
export var queryGiftActivity = function queryGiftActivity(params) {
  return request({
    url: '/operation/limitActivity',
    method: 'get',
    params: params
  });
};
export var deleteGiftActivity = function deleteGiftActivity(params) {
  return request({
    url: '/operation/limitActivity',
    method: 'delete',
    data: params
  });
};
export var queryOrders = function queryOrders(params) {
  return request({
    url: '/operation/queryOrders',
    method: 'get',
    params: params
  });
};
export var handleExport = function handleExport(params) {
  return request({
    url: '/operation/handleExport',
    method: 'get',
    params: params
  });
};
export var handleFeedbackExport = function handleFeedbackExport(params) {
  return request({
    url: '/operation/handleFeedbackExport',
    method: 'get',
    params: params
  });
};
export var queryFeedback = function queryFeedback(params) {
  return request({
    url: '/operation/feedback',
    method: 'get',
    params: params
  });
};
export var updateFeedback = function updateFeedback(params) {
  return request({
    url: '/operation/feedback',
    method: 'put',
    data: params
  });
};
export var queryHeaderImage = function queryHeaderImage(params) {
  return request({
    url: '/operation/headerImage',
    method: 'get',
    params: params
  });
};
export var updateHeaderImage = function updateHeaderImage(params) {
  return request({
    url: '/operation/headerImage',
    method: 'put',
    data: params
  });
};
export var saveBagMall = function saveBagMall(params) {
  return request({
    url: '/operation/bagMall',
    method: 'post',
    data: params
  });
};
export var updateBagMall = function updateBagMall(params) {
  return request({
    url: '/operation/bagMall',
    method: 'put',
    data: params
  });
};
export var queryBagMall = function queryBagMall(params) {
  return request({
    url: '/operation/bagMall',
    method: 'get',
    params: params
  });
};
export var deleteBagMall = function deleteBagMall(params) {
  return request({
    url: '/operation/bagMall',
    method: 'delete',
    data: params
  });
};
export var saveOrUpdateVipDiscount = function saveOrUpdateVipDiscount(params) {
  return request({
    url: '/operation/saveOrUpdateVipDiscount',
    method: 'post',
    data: params
  });
};
export var queryVipDiscount = function queryVipDiscount(params) {
  return request({
    url: '/operation/queryVipDiscount',
    method: 'get',
    params: params
  });
};
export var saveOrUpdateGacha = function saveOrUpdateGacha(params) {
  return request({
    url: '/operation/saveOrUpdateGacha',
    method: 'post',
    data: params
  });
};
export var queryGacha = function queryGacha(params) {
  return request({
    url: '/operation/queryGacha',
    method: 'get',
    params: params
  });
};
export var queryReport = function queryReport(params) {
  return request({
    url: '/stat/queryReport',
    method: 'get',
    params: params
  });
};
export var queryActivity = function queryActivity(params) {
  return request({
    url: '/stat/queryActivity',
    method: 'get',
    params: params
  });
};
export var queryTaskStat = function queryTaskStat(params) {
  return request({
    url: '/stat/queryTaskStat',
    method: 'get',
    params: params
  });
};
export var queryFruitMachine = function queryFruitMachine(params) {
  return request({
    url: '/stat/queryFruitMachine',
    method: 'get',
    params: params
  });
};
export var handlePropExport = function handlePropExport(params) {
  return request({
    url: '/stat/handlePropExport',
    method: 'get',
    params: params
  });
};
export var handleItemExport = function handleItemExport(params) {
  return request({
    url: '/stat/handleItemExport',
    method: 'get',
    params: params
  });
};
export var handleBagExport = function handleBagExport(params) {
  return request({
    url: '/stat/handleBagExport',
    method: 'get',
    params: params
  });
};
export var handleFruitExport = function handleFruitExport(params) {
  return request({
    url: '/stat/handleFruitExport',
    method: 'get',
    params: params
  });
};
export var queryBag = function queryBag(params) {
  return request({
    url: '/stat/queryBag',
    method: 'get',
    params: params
  });
};
export var queryPage = function queryPage(params) {
  return request({
    url: '/stat/queryPage',
    method: 'get',
    params: params
  });
};
export var queryUser = function queryUser(params) {
  return request({
    url: '/stat/queryUser1',
    method: 'get',
    params: params
  });
};
export var queryRechargeSource = function queryRechargeSource(params) {
  return request({
    url: '/stat/queryRechargeSource',
    method: 'get',
    params: params
  });
};
export var queryMediaSource = function queryMediaSource(params) {
  return request({
    url: '/stat/queryMediaSource',
    method: 'get',
    params: params
  });
};
export var batchDealPass = function batchDealPass(params) {
  return request({
    url: '/operation/batchDealPass',
    method: 'post',
    data: params
  });
};
export var updateOrder = function updateOrder(params) {
  return request({
    url: '/operation/updateOrder',
    method: 'post',
    data: params
  });
};
export var saveMarquee = function saveMarquee(params) {
  return request({
    url: '/operation/marquee',
    method: 'post',
    data: params
  });
};
export var queryMarquee = function queryMarquee(params) {
  return request({
    url: '/operation/marquee',
    method: 'get',
    params: params
  });
};
export var chatList = function chatList(params) {
  return request({
    url: '/operation/chatList',
    method: 'get',
    params: params
  });
};